import { Box, Button, TextField, Typography } from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";

function DescribeStep({ handleNext, handleBack, text, onTextChange }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)"  }}>
        {t("Describe your project")}
      </Typography>
      <Box>
        <TextField
          label={t("What do you want to see in the project?")}
          id="text"
          required
          multiline
          minRows={15}
          maxRows={25}
          variant="filled"
          fullWidth
          onChange={event => onTextChange(event.target.value)}
          value={text}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          onClick={handleBack}
        >
          {t("Back")}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={handleNext}
          disabled={text == null}
        >
          {t("Next")}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default DescribeStep;