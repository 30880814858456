import React from "react";
import useGetConfig from "../../common/hooks/GetConfigHook";
import classes from "./AboutPage.module.css";
import {useTranslateContent} from '../../i18n';

function AboutPage() {
    const { data, update } = useGetConfig();
    const ct = useTranslateContent();

    React.useEffect(() => {
        update("about-page");
    }, [update]);

    if (!data) {
        return null;
      }

    return (
        <div className={classes.Content}>
            <div className={classes.ContentWrapper} dangerouslySetInnerHTML={{ __html: ct(data.description) }}/>
        </div>
    );
}

export default AboutPage;