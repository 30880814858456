import React from "react";
import { useParams } from "react-router-dom";
import useGetOne from "../../common/hooks/GetOneHook";
import classes from "./ProjectPage.module.css";
import {useTranslateContent} from '../../i18n';

function ProjectPage() {
  const { id } = useParams();
  const { data, update } = useGetOne();
  const ct = useTranslateContent();

  React.useEffect(() => {
    update("projects", { id: id });
  }, [update, id]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.Content}>
      <h1>{ct(data.title)}</h1>
      <div className={classes.VideoWrapper}>
        <iframe
          className={classes.Video}
          src={`${data.videoUrl}?loop=1&showinfo=0`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className={classes.ContentWrapper} dangerouslySetInnerHTML={{ __html: ct(data.description) }}/>
    </div>
  );
}

export default ProjectPage;
