import axios from "axios";
import React from "react";

const useRequest = () => {
  const [data, setData] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSended, setIsSended] = React.useState(false);

  const axiosInstance = React.useMemo(
    () =>
      axios.create({
        baseURL: `${window["env"]["useSsl"] ? "https" : "http"}://${window["env"]["apiDomain"]}`,
        paramsSerializer: {
          indexes: null,
        },
      }),
    []
  );

  const reset = React.useCallback(
    () => {
      setIsSended(false);
    },
    []
  );

  const update = React.useCallback(
    (request) => {
      setLoading(true);
      setIsSended(true);
      request(axiosInstance).then((data) => {
        setData(data);
        setLoading(false);
      });
    },
    [axiosInstance]
  );

  return { data, isLoading, update, reset, isSended };
};

export default useRequest;
