import React from "react";
import useRequest from "./RequestHook";

const useGetList = () => {
  const { data, isLoading, update } = useRequest();

  const newUpdate = React.useCallback(
    (resource, params) => {
      update((axiosInstance) => {
        let requestParams = {
          sortBy: params?.sort?.field,
          sortDirection: params?.sort?.order,
          pageNumber: params?.pagination?.page,
          pageSize: params?.pagination?.perPage,
          ...params.filter,
        };

        return axiosInstance
          .get(`/api/v1/${resource}`, { params: requestParams })
          .then((response) => {
            return response.data;
          });
      });
    },
    [update]
  );

  return { data, isLoading, update: newUpdate };
};

export default useGetList;
