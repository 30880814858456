import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    initImmediate: false,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          "Our projects": "Our projects",
          "About company": "About company",
          "Contacts": "Contacts",
          "More info": "More info",
          "All projects": "All projects",
          "Unity developer vacancy": "Unity developer vacancy",
          "Your name": "Your name...",
          "Your email": "Your email...",
          "Describe your experience": "Describe your experience",
          "Send": "Send",
          "Sended": "Sended",
          "Our partners": "Our partners",
          "Socials": "Socials",
          "Follow us": "Follow us",
          "Next": "Next",
          "Back": "Back",
          "Order a project": "Order a project",
          "Order": "Order",
          "Select game type": "Select game type",
          "Describe your project": "Describe your project",
          "What do you want to see in the project?": "What do you want to see in the project?",
          "Choose a payment method": "Choose a payment method",
          "Communication method": "Communication method",
          "sendToFiverText": "Please send us a message with the text: <1>{{message}}</1> (click to copy to clipboard) to our <3>fiver account</3>.",
          "fiverMessage": "Hello, I made order number {{id}} on your website",
          "Message text copied": "Message text copied!",
          "Send message to our fiver": "Send message to our fiver",
          "Send an order": "Send an order",
          "We will contact you shortly": "We will contact you shortly",
        }
      },
      ru: {
        translation: {
          "Our projects": "Наши проекты",
          "About company": "О компании",
          "Contacts": "Контакты",
          "More info": "Подробнее",
          "All projects": "Все проекты",
          "Unity developer vacancy": "Вакансия Unity Developer (Проектная занятость)",
          "Your name": "Ваше имя...",
          "Your email": "Ваш email...",
          "Describe your experience": "Опишите свой опыт",
          "Send": "Отправить",
          "Sended": "Отправлено",
          "Our partners": "Наши партнеры",
          "Socials": "Социальные сети",
          "Follow us": "Мы в социальных сетях",
          "Next": "Далее",
          "Back": "Назад",
          "Order a project": "Заказать проект",
          "Order": "Заказать",
          "Select game type": "Выберите тип игры",
          "Describe your project": "Опишите свой проект",
          "What do you want to see in the project?": "Что вы хотите увидеть в проекте?",
          "Choose a payment method": "Выберите способ оплаты",
          "Communication method": "Способ связи",
          "sendToFiverText": "Пожалуйста отправьте сообщение с текстом: <1>{{message}}</1> (нажмите чтобы скопировать в буфер обмена) на наш <3>fiver аккаунт</3>.",
          "fiverMessage": "Привет, я сделал заказ {{id}} на вашем сайте",
          "Message text copied": "Текст сообщения скопирован!",
          "Send message to our fiver": "Отправить сообщение на наш fiver",
          "Send an order": "Отправить заказ",
          "We will contact you shortly": "Мы свяжемся с вами в ближайшее время",
        }
      }
    },
  });

export const useTranslateContent = () => {
  const { i18n } = useTranslation();

  const translator = (content) => {
    if (content == null) {
      return null;
    }

    if (content.hasOwnProperty(i18n.language)) {
      return content[i18n.language];
    }
    return content.en;
  }

  return translator;
};
