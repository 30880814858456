import React from "react";
import classes from "./DescriptionCard.module.css";

function DescriptionCard({ description }) {
  return (
    <section className={classes.DescriptionCard}>
      <img
        className={classes.Image}
        src="/LogoWithOutline.png"
        alt="brain logo"
      />
      <div className={classes.Data} dangerouslySetInnerHTML={{ __html: description }}/>
    </section>
  );
}

export default DescriptionCard;
