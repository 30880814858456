import React from "react";
import useRequest from "./RequestHook";

const useGetOne = () => {
  const { data, isLoading, update } = useRequest();

  const newUpdate = React.useCallback(
    (resource, params) => {
      update((axiosInstance) => {
        let url = `/api/v1/${resource}/${params.id}`;

        return axiosInstance.get(url).then((response) => {
          return response.data
        });
      });
    },
    [update]
  );

  return { data, isLoading, update: newUpdate };
};

export default useGetOne;
