import classes from "./Partner.module.css";

function Partner({ imageUrl, imageAlt, name, link }) {
  return (
    <li className={classes.Partner}>
      <a href={link}>
        <img src={imageUrl} alt={imageAlt}/>
      </a>
    </li>
  );
}

export default Partner;