import React from "react";
import useGetConfig from "../../common/hooks/GetConfigHook";
import useGetList from "../../common/hooks/GetListHook";
import SocialIcon from "./components/SocialIcon/SocialIcon";
import classes from "./ContactsPage.module.css";
import {useTranslateContent} from '../../i18n';
import { useTranslation } from "react-i18next";

function ContactsPage() {
  const { data, update } = useGetConfig();
  const { data: contacts, update: updateContacts } = useGetList();
  const ct = useTranslateContent();
  const { t } = useTranslation();

  React.useEffect(() => {
    updateContacts("contacts", {});
  }, [updateContacts]);

  React.useEffect(() => {
    update("contacts-page");
  }, [update]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.Content}>
      <div className={classes.SocialWrapper}>
        <h2>{t("Follow us")}</h2>
        <ul className={classes.SocialList}>
          {contacts != null && contacts.map((item) => (
            <SocialIcon
              key={item.id}
              image={item.picture.src}
              name={item.name}
              link={item.url}
            />
          ))}
        </ul>
      </div>
      <div>
        <h2>{ct(data.title)}</h2>
        <div className={classes.Description} dangerouslySetInnerHTML={{ __html: ct(data.description) }} />
      </div>
    </div>
  );
}

export default ContactsPage;
