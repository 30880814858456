import { Card } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import classes from "./CustomSelectCard.module.css";

function CustomSelectCard({ selected, onSelect }) {
    const { t } = useTranslation();

    return (
        <Card className={classes.Card}
            onClick={() => {
                onSelect(null);
            }}
        >
            <div className={classes.CardRation}>
                <div className={classes.Content}>
                    <h2>{t("Custom")}</h2>
                </div>
            </div>
            <div className={`${classes.Fade} ${selected ? classes.Selected : ""}`}>
                <div className={classes.SelectedText}>
                    {t("Selected")}
                </div>
            </div>
        </Card>
    );
}

export default CustomSelectCard;