import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import React from "react";
import { Trans, useTranslation } from "react-i18next";

function WriteToFiverrStep({ id, handleNext, handleBack }) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const message = t("fiverMessage", { id });

    const handleCopy = () => {
        navigator.clipboard.writeText(message);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)"  }}>
                {t("Send message to our fiver")}
            </Typography>
            <Box sx={{display: "grid", justifyItems: "center", alignItems: "center", height: "500px", textAlign: "center"}}>
                <div>
                    <p>
                        <Trans i18nKey="sendToFiverText">
                            Please send us a message with the text:
                            <Button onClick={handleCopy}>
                                {{
                                    // @ts-ignore
                                    message
                                }}
                            </Button>
                            (сlick to copy to clipboard) to our
                            <Button href="https://www.fiverr.com/kirillsherbaev2" target="_blank">
                                fiver account
                            </Button>
                        </Trans>
                    </p>
                </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                >
                    {t("Back")}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={handleNext}
                >
                    {t("Next")}
                </Button>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t("Message text copied")}
                </Alert>
            </Snackbar>
        </>
    );
}

export default WriteToFiverrStep;