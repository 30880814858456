import { Card } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import classes from "./ProjectSelectCard.module.css";

function ProjectSelectCard({ id, title, imageUrl, imageAlt, videoUrl, selected, onSelect }) {
  const [isVideoShowing, setVideoShowing] = useState(false);
  const { t } = useTranslation();

  return (
    <Card className={classes.Card}
      onClick={() => {
        onSelect(id);
      }}
      onMouseOver={(e) => {
        setVideoShowing(true);
      }}
      onMouseOut={(e) => {
        setVideoShowing(false);
      }}>
      <div className={classes.ImageContainer}>
        {isVideoShowing && (
          <iframe
            className={classes.Video}
            src={`${videoUrl}?autoplay=1&mute=1&controls=0&loop=1&showinfo=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        <img className={classes.Image} src={imageUrl} alt={imageAlt} />
        <img
          className={classes.ImageBackground}
          src={imageUrl}
          alt={imageAlt}
        />
      </div>
      <div className={classes.TitleWrapper}>
        <div className={classes.Title}>
          {title}
        </div>
      </div>
      <div className={`${classes.Fade} ${selected ? classes.Selected : ""}`}>
        <div className={classes.SelectedText}>
          {t("Selected")}
        </div>
      </div>
    </Card>
  );
}

export default ProjectSelectCard;