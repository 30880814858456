import { Box, Button, Typography } from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";

function FinalStep({ handleBack }) {
    const { t } = useTranslation();

    return (
        <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)"  }}>
                {t("Send an order")}
            </Typography>
            <Box sx={{display: "grid", justifyItems: "center", alignItems: "center", height: "500px"}}>
                <h2>{t("We will contact you shortly")}</h2>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                >
                    {t("Back")}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
            </Box>
        </>
    );
}

export default FinalStep;