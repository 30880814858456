import { Box, Button, Typography } from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./PaymentSelect.module.css";

function PaymentSelectStep({ handleNext, handleBack, onSelect, selected }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)"  }}>
        {t("Choose a payment method")}
      </Typography>
      <Box className={classes.PaymentSelect}>
        <div className={`${classes.Payment} ${selected === "cryptocurrency" || selected == null ? classes.Selected : ""}`} onClick={() => onSelect("cryptocurrency")}>
          <img src="/cryptocurrency-payment.png" alt="cryptocurrency payment" width={"400px"} />
        </div>
        <div className={`${classes.Line}`} />
        <div className={`${classes.Payment} ${selected === "fiverr" || selected == null ? classes.Selected : ""}`} onClick={() => onSelect("fiverr")}>
          <img src="/fiver-payment.png" alt="fiver payment" width={"400px"} />
        </div>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          onClick={handleBack}
        >
          {t("Back")}
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={handleNext}
          disabled={selected == null}
        >
          {t("Next")}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default PaymentSelectStep;