import React from "react";
import useGetConfig from "../../common/hooks/GetConfigHook";
import AdvantagesCard from "./components/AdvantagesCard/AdvantagesCard";
import DescriptionCard from "./components/DescriptionCard/DescriptionCard";
import FormCard from "./components/FormCard/FormCard";
import PartnersCard from "./components/PartnersCard/PartnersCard";
import ProductsCard from "./components/ProductsCard/ProductsCard";
import { useTranslateContent } from "../../i18n";

function MainPage() {
  const { data, update } = useGetConfig();
  const ct = useTranslateContent();

  React.useEffect(() => {
    update("main-page");
  }, [update]);

  return (
    <div>
      <div>
        <AdvantagesCard
          companyName={data?.name}
        />
      </div>
      <div>
        <ProductsCard/>
      </div>
      <div>
        <FormCard/>
      </div>
      <div>
        <DescriptionCard description={ct(data?.description)}/>
      </div>
      <div>
        <PartnersCard/>
      </div>
    </div>
  );
}

export default MainPage;
