import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
function AddContactStep({ value, onValueChange, handleNext, handleBack }) {
    const { t } = useTranslation();
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            setCountryCode(data.country_calling_code);
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    const SocialSelect = {
        telegram: {
            title: "telegram",
            defaultValue: {
                telephone: countryCode,
            },
            component: (
                <MuiTelInput fullWidth value={value?.telephone} onChange={telephone => onValueChange({ ...value, telephone: telephone })} />
            ),
            validator: (data) => {
                let validator = /^\+[0-9]{1,3}\s[0-9\s]{6,15}$/;
                return validator.test(data.telephone);
            }
        },
        whatsapp: {
            title: "whatsapp",
            defaultValue: {
                telephone: countryCode,
            },
            component: (
                <MuiTelInput fullWidth value={value?.telephone} onChange={telephone => onValueChange({ ...value, telephone: telephone })} />
            ),
            validator: (data) => {
                let validator = /^\+[0-9]{1,3}\s[0-9\s]{6,15}$/;
                return validator.test(data.telephone);
            }
        },
        discord: {
            title: "discord",
            defaultValue: {
                userName: "",
            },
            component: (
                <TextField label={t("User name")} variant="outlined" fullWidth value={value?.userName} onChange={event => onValueChange({ ...value, userName: event.target.value })} />
            ),
            validator: (data) => {
                return data.userName != null && data.userName !== "";
            }
        },
        email: {
            title: "email",
            defaultValue: {
                email: "",
            },
            component: (
                <TextField label={t("Your email")} variant="outlined" fullWidth value={value?.email} onChange={event => onValueChange({ ...value, email: event.target.value })} />
            ),
            validator: (data) => {
                let re = /\S+@\S+\.\S+/;
                return re.test(data.email);
            }
        },
    };

    return (
        <>
            <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)" }}>
                {t("Communication method")}
            </Typography>
            <FormControl fullWidth>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: "20px" }}>
                    <InputLabel id="communication-method-label">{t("Communication method")}</InputLabel>
                    <Select
                        labelId='communication-method-label'
                        label={t("Communication method")}
                        fullWidth
                        value={value?.social ?? ""}
                        onChange={event => {
                            onValueChange({ ...SocialSelect[event.target.value].defaultValue, social: event.target.value })
                        }}
                    >
                        {Object.keys(SocialSelect).map((key) => (
                            <MenuItem
                                key={key}
                                value={key}
                            >
                                {SocialSelect[key].title}
                            </MenuItem>
                        ))}
                    </Select>
                    {
                        value?.social && SocialSelect[value?.social]?.component
                    }
                </Box>
            </FormControl>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    onClick={handleBack}
                >
                    {t("Back")}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={handleNext}
                    disabled={SocialSelect[value?.social]?.validator(value) === false}
                >
                    {t("Next")}
                </Button>
            </Box>
        </>
    );
}

export default AddContactStep;