import classes from "./PartnersCard.module.css";
import Partner from "./components/Partner/Partner";
import useGetList from "../../../../common/hooks/GetListHook";
import React from "react";
import {useTranslation} from 'react-i18next';

function PartnersCard() {
  const { data, update } = useGetList();
  const { t } = useTranslation();

  React.useEffect(() => {
    update("partners", {});
  }, [update]);

  if (!data) {
    return null;
  }

  return (
    <section className={classes.PartnersCard}>
      <h2>{t("Our partners")}</h2>
      <ul className={classes.Cards}>
        {data.map((item) => (
          <Partner
            key={item.id}
            name={item.name}
            imageUrl={item.picture.src}
            imageAlt={item.picture.title}
            link={item.url}
          />
        ))}
      </ul>
    </section>
  );
}

export default PartnersCard;
