import React from "react";
import useGetList from "../../../../common/hooks/GetListHook";
import { useTranslateContent } from '../../../../i18n';
import classes from "./AdvantagesCard.module.css";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AdvantagesCard({ companyName }) {
  const { data, update } = useGetList();
  const [selected, setSelected] = React.useState(0);
  const tc = useTranslateContent();
  const { t } = useTranslation();

  React.useEffect(() => {
    update("advantages", {});
  }, [update]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSelected(selected => (selected + 1) % data.length)
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [data, selected]);

  if (!data) {
    return null;
  }

  return (
    <section className={classes.MainCard}>
      <div className={classes.Carousel}>
        <div className={classes.CarouselWrapper}>
          <div
            className={classes.CarouselList}
            style={{
              width: `${100 * data.length}%`,
              marginLeft: `${-100 * selected}%`,
            }}
          >
            {data.map((item) => (
              <div key={item.id} className={classes.CarouselItem}>
                <img
                  className={classes.Image}
                  src={item.picture.src}
                  alt={item.picture.title}
                  width="100%"
                  height="100%"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.Data}>
        <div className={classes.Info}>
          <div className={classes.CompanyName}>{companyName}</div>
          <div className={classes.Title}>{tc(data[selected]?.title)}</div>
          <div
            className={classes.Description}
            dangerouslySetInnerHTML={{ __html: tc(data[selected]?.description) }}
          />
        </div>
        <div className={classes.ButtonContainer}>
          <Link to="create-order" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                padding: "20px 40px",
                fontSize: "20px",
                fontWeight: "bold",
                borderRadius: "50px",
                background: "linear-gradient(45deg, rgb(243, 112, 30), #fe2702)"
              }}>
              {t("Order a project")}
            </Button>
          </Link>
        </div>
        <div className={classes.Selector}>
          {data.map((item, index) => (
            <div
              key={item.id}
              className={classes.SelectButtonWrapper}
              onClick={() => {
                setSelected(index);
              }}
            >
              <div
                className={
                  index === selected
                    ? `${classes.SelectButton} ${classes.ActiveSelectButton}`
                    : `${classes.SelectButton}`
                }
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AdvantagesCard;
