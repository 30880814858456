import React from "react";
import classes from "./SocialIcon.module.css";

function SocialIcon({ image, name, link }) {
  return (
    <li className={classes.SocialIcon}>
      <a className={classes.Link} href={link}>
        <img src={image} alt={name} />
      </a>
    </li>
  );
}

export default SocialIcon;
