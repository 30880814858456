import React from "react";
import useRequest from "./RequestHook";

const useGetConfig = () => {
  const { data, isLoading, update } = useRequest();

  const newUpdate = React.useCallback(
    (resource) => {
      update((axiosInstance) => {
        let url = `/api/v1/${resource}`;

        return axiosInstance.get(url).then((response) => {
          return response.data
        });
      });
    },
    [update]
  );

  return { data, isLoading, update: newUpdate };
};

export default useGetConfig;