import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import MainPage from "./pages/MainPage/MainPage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import { ThemeProvider, createTheme } from "@mui/material";
import OrderPage from "./pages/OrderPage/OrderPage";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(243, 112, 30)',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <main className="Content">
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          <Route path="/projects" element={<ProjectsPage/>} />
          <Route path="/projects/:id" element={<ProjectPage/>} />
          <Route path="/about" element={<AboutPage/>} />
          <Route path="/contacts" element={<ContactsPage/>} />
          <Route path="/create-order" element={<OrderPage/>} />
        </Routes>
      </main>
      <Footer />
      </ThemeProvider>
  );
}

export default App;
