import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import DescribeStep from "./DescribeStep/DescribeStep";
import classes from "./OrderPage.module.css";
import PaymentSelectStep from "./PaymentSelectStep/PaymentSelectStep";
import ProjectSelectStep from "./ProjectSelectStep/ProjectSelectStep";
import WriteToFiverrStep from "./WriteToFiverStep/WriteToFiverStep";
import { useTranslation } from "react-i18next";
import AddContactStep from "./AddContactStep/AddContactStep";
import FinalStep from "./FinalStep/FinalStep";
import useCreate from "../../common/hooks/CreateHook";

function OrderPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [data, setData] = React.useState({
    gameId: undefined,
    text: undefined,
    payment: undefined,
    contacts: undefined
  });
  const { t } = useTranslation();
  const { create } = useCreate();

  const handleNextWithFiverrCheck = () => {
    if (data.payment === "fiverr") {
      const date = new Date();
      const newData = {
        ...data,
        contacts: {
          social: "fiverr",
          orderId: date.getTime()
        }
      }
      setData(newData)
      handleSubmit(newData)
    }

    handleNext();
  };

  const handleNextWithSendData = () => {
    handleSubmit(data)

    handleNext();
  };

  const handleSubmit = (submitData) => {
    create("order", { data: submitData });
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      title: t('Select game type'),
      component: (
        <ProjectSelectStep
          handleNext={handleNext}
          selectedId={data.gameId}
          onSelectChange={id => {
            setData({ ...data, gameId: id });
            handleNext();
          }}
        />
      )
    },
    {
      title: t('Describe your project'),
      component: (
        <DescribeStep
          handleNext={handleNext}
          handleBack={handleBack}
          text={data.text}
          onTextChange={text => setData({ ...data, text: text })}
        />
      )
    },
    {
      title: t('Choose a payment method'),
      component: (
        <PaymentSelectStep
          handleNext={handleNextWithFiverrCheck}
          handleBack={handleBack}
          onSelect={payment => setData({ ...data, payment: payment })}
          selected={data.payment}
        />
      )
    },
    {
      title: t('Communication method'),
      component: (
        <>
          {data.payment === "fiverr" ? (
            <WriteToFiverrStep
              id={data?.contacts?.orderId}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          ) : (
            <AddContactStep
              value={data.contacts}
              onValueChange={value => setData({ ...data, contacts: value })}
              handleNext={handleNextWithSendData}
              handleBack={handleBack}
            />
          )}
        </>
      )
    },
    {
      title: t('Send an order'),
      component: (
        <FinalStep handleBack={handleBack} />
      )
    }];

  return (
    <div className={classes.Content}>
      <div className={classes.StepperWrapper}>
        <Stepper activeStep={activeStep} sx={{minWidth: "1000px"}}>
          {steps.map((step, index) => {
            const stepProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {steps[activeStep].component}
    </div>
  );
}

export default OrderPage;