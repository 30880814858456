import React from 'react';
import { useTranslation } from 'react-i18next';
import useCreate from '../../../../common/hooks/CreateHook';
import classes from './FormCard.module.css';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { green } from '@mui/material/colors';

function FormCard() {
  const { t } = useTranslation();
  const { isLoading, create, isSended, reset } = useCreate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const email = event.target.email.value;
    const text = event.target.text.value;

    console.log(name, email, text);

    create("vacancy", { data: { name, email, text } });
  };

  const buttonSx = {
    ...(isSended && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <section className={classes.FormCard}>
      <h2>
        {t("Unity developer vacancy")}
      </h2>
      <div className={classes.Form}>
        <form onChange={reset} onSubmit={handleSubmit}>
          <TextField id="name" fullWidth label={t("Your name")} required variant="filled"
            InputProps={{
              style: { backgroundColor: '#ffffff', marginBottom: '20px' }
            }} />
          <TextField id="email" type='email' fullWidth label={t("Your email")} required variant="filled"
            InputProps={{
              style: { backgroundColor: '#ffffff', marginBottom: '20px' }
            }} />
          <TextField
            label={t("Describe your experience")}
            id="text"
            required
            multiline
            minRows={7}
            maxRows={15}
            variant="filled"
            fullWidth
            InputProps={{
              style: { backgroundColor: '#ffffff', marginBottom: '20px' }
            }} />
          <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'end' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                variant="text"
                disabled={isLoading}
                type="submit"
                sx={buttonSx}
              >
                {isSended ? t("Sended") : t("Send")}
              </Button>
              { isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </form>
      </div>
    </section>
  );
}

export default FormCard;