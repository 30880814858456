import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useGetList from "../../../common/hooks/GetListHook";
import { useTranslateContent } from "../../../i18n";
import CustomSelectCard from "./components/CustomSelectCard/CustomSelectCard";
import ProjectSelectCard from "./components/ProjectSelectCard/ProjectSelectCard";
import classes from "./ProjectSelect.module.css";

function ProjectSelectStep({ handleNext, selectedId, onSelectChange }) {
  const { data, update } = useGetList();
  const { data: categoriesData, update: updateCategories } = useGetList();
  const ct = useTranslateContent();
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);

  React.useEffect(() => {
    update("projects", {
      pagination: {
        page: 1,
        perPage: 6,
      },
      filter: {
        categories: selectedCategories,
      },
    });
  }, [selectedCategories, update]);

  React.useEffect(() => {
    updateCategories("categories", {});
  }, [updateCategories]);

  if (!data) {
    return null;
  }

  const onCategoryClick = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((item) => item !== id));
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  return (
    <React.Fragment>
      <Typography sx={{ mt: 2, mb: 1, py: 1, color: "rgba(0, 0, 0, 0.5)" }}>
        {t("Select game type")}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            width: "100%",
            pb: "20px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {categoriesData?.map((item) => (
            <Chip
              label={ct(item.name)}
              key={item.id}
              variant={
                selectedCategories.includes(item.id) ? "filled" : "outlined"
              }
              color={
                selectedCategories.includes(item.id) ? "primary" : "default"
              }
              onClick={() => onCategoryClick(item.id)}
            />
          ))}
        </Box>
        <Box className={classes.Items}>
          {data.data.map((item) => (
            <ProjectSelectCard
              key={item.id}
              id={item.id}
              title={ct(item.title)}
              imageUrl={item.picture.src}
              imageAlt={item.picture.title}
              videoUrl={item.videoUrl}
              selected={item.id === selectedId}
              onSelect={onSelectChange}
            />
          ))}
          <CustomSelectCard
            selected={selectedId === null}
            onSelect={onSelectChange}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button color="inherit" disabled={true} sx={{ mr: 1 }}>
          {t("Back")}
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button onClick={handleNext} disabled={selectedId === undefined}>
          {t("Next")}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default ProjectSelectStep;
