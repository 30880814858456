import React from "react";
import useGetList from "../../common/hooks/GetListHook";
import ProductCard from "../../components/ProductCard/ProductCard";
import classes from "./ProjectsPage.module.css";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../i18n";
import { Pagination, Stack } from "@mui/material";

const perPage = 5;

function ProjectsPage() {
  const { data, update } = useGetList();
  const [page, setPage] = React.useState(1);
  const { t } = useTranslation();
  const ct = useTranslateContent();

  React.useEffect(() => {
    update("projects", {
      pagination: {
        page: page,
        perPage: perPage,
      },
    });
  }, [update, page]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.Products}>
      <h2>{t("Our projects")}</h2>
      <div className={classes.Cards}>
        {data.data.map((item) => (
          <ProductCard
            key={item.id}
            title={ct(item.title)}
            description={ct(item.description)}
            imageUrl={item.picture.src}
            imageAlt={item.picture.title}
            videoUrl={item.videoUrl}
            id={item.id}
          />
        ))}
      </div>
      <Stack spacing={2} sx={{alignItems: "center"}}>
        <Pagination count={Math.ceil(data.total / perPage)} defaultPage={page} onChange={(event, value) => setPage(value)} />
      </Stack>

    </div>
  );
}

export default ProjectsPage;
