import React from "react";
import useGetList from "../../common/hooks/GetListHook";
import SocialIcon from "./components/SocialIcon/SocialIcon";
import classes from "./Footer.module.css";
import {useTranslation} from 'react-i18next';

function Footer() {
  const { data, update } = useGetList();
  const { t } = useTranslation();

  React.useEffect(() => {
    update("contacts", {});
  }, [update]);

  return (
    <footer className={classes.Footer}>
      <div className={classes.Container}>
        <div className={classes.Social}>
          {data != null && (
            <>
              <h4>{t("Socials")}:</h4>
              <ul className={classes.SocialList}>
                {data.map((item) => (
                  <SocialIcon
                    key={item.id}
                    image={item.picture.src}
                    name={item.name}
                    link={item.url}
                  />
                ))}
              </ul>
            </>
          )}
        </div>
        <div className={classes.LogoWrapper}>
          <img className={classes.Logo} src="/logo2.png" alt="Logo" />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
