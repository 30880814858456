import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import classes from "./ProductCard.module.css";

function ProductCard({ title, description, imageUrl, imageAlt, videoUrl, id }) {
  const [isVideoShowing, setVideoShowing] = useState(false);
  const { t } = useTranslation();

  return (
    <Card className={classes.ProductCard}>
      <div
        className={classes.ImageConatainer}
        onMouseOver={(e) => {
          setVideoShowing(true);
        }}
        onMouseOut={(e) => {
          setVideoShowing(false);
        }}
      >
        {isVideoShowing && (
          <iframe
            className={classes.Video}
            src={`${videoUrl}?autoplay=1&mute=1&controls=0&loop=1&showinfo=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        <img className={classes.Image} src={imageUrl} alt={imageAlt} />
        <img
          className={classes.ImageBackground}
          src={imageUrl}
          alt={imageAlt}
        />
      </div>
      <CardContent>
        <Typography gutterBottom variant="h4" component="div" className={classes.Title}>
          {title}
        </Typography>
        
          <div
            className={classes.Description}
            dangerouslySetInnerHTML={{ __html: description }}
          />

      </CardContent>
      <CardActions style={{float: 'right'}}>
        <Link to={`/projects/${id}`} style={{ textDecoration: 'none' }}>
          <Button variant="text" className={classes.Button} >{t("More info")}</Button>
        </Link>
      </CardActions>
    </Card>
  );
}

export default ProductCard;
