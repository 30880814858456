import React from "react";
import { Link } from "react-router-dom";
import useGetList from "../../../../common/hooks/GetListHook";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import classes from "./ProductsCard.module.css";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../../../i18n";
import { Button } from "@mui/material";

function ProductsCard() {
  const { data, update } = useGetList();
  const { t } = useTranslation();
  const ct = useTranslateContent();

  React.useEffect(() => {
    update("projects", {
      pagination: {
        page: 1,
        perPage: 6,
      },
    });
  }, [update]);

  if (!data) {
    return null;
  }

  return (
    <section className={classes.MoreInfoCard}>
      <h2>{t("Our projects")}</h2>
      <div className={classes.Cards}>
        {data.data.map((item) => (
          <ProductCard
            key={item.id}
            title={ct(item.title)}
            description={ct(item.description)}
            imageUrl={item.picture.src}
            imageAlt={item.picture.title}
            videoUrl={item.videoUrl}
            id={item.id}
          />
        ))}
      </div>
      <div className={classes.ButtonContainer}>
        <Link to="projects" style={{ textDecoration: 'none' }}>
          <Button variant="text" >{t("All projects")}</Button>
        </Link>
      </div>
    </section>
  );
}

export default ProductsCard;
