import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, FormControl, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';

const navButtons = [
  {
    translationKey: "Our projects",
    navigationKey: "projects"
  },
  {
    translationKey: "Contacts",
    navigationKey: "contacts"
  },
  {
    translationKey: "About company",
    navigationKey: "about"
  }
]

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { t, i18n } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
      <Container sx={{ maxWidth: "1428px !important" }}>
        <Toolbar disableGutters sx={{ display: { xs: 'none', md: 'grid' }, gridTemplateColumns: "1fr 3fr 1fr" }}>
          <div>
            <NavLink to="" style={{height: "100%", display: "flex", alignItems: "center" }}>
              <img src="/logo.png" alt="Logo" height={"60px"}/>
            </NavLink>
          </div>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {navButtons.map((item, index) => (
              <Link key={index} to={item.navigationKey} style={{ textDecoration: 'none' }}>
                <Button
                  sx={{
                    color: 'black', display: 'block', ':hover': {
                      bgcolor: '#f0f0f0',
                    },
                  }}
                >
                  {t(item.translationKey)}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", gap: "20px" }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
              <Select
                id="language"
                onChange={changeLanguage}
                value={i18n.language}
              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"ru"}>RU</MenuItem>
              </Select>
            </FormControl>
            <Link to="create-order">
              <Button
                variant="contained"
                sx={{ borderRadius: "50px", height: "100%", background: "linear-gradient(45deg, rgb(243, 112, 30), #fe2702)" }}
              >
                {t("Order")}
              </Button>
            </Link>
          </Box>
        </Toolbar>
        <Toolbar disableGutters sx={{ display: { xs: 'grid', md: 'none' }, gridTemplateColumns: "1fr 3fr 1fr" }}>
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{ color: 'black' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navButtons.map((item, index) => (
                <Link key={index} to={item.navigationKey} style={{ textDecoration: 'none', color: "black" }}>
                  <MenuItem key={item.translationKey} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{t(item.translationKey)}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <div style={{display: "flex", justifyContent: "center"}}>
            <NavLink to="">
              <img src="/logo.png" alt="Logo" height={"60px"}/>
            </NavLink>
          </div>
          <Box sx={{ display: "flex", justifyContent: "end", gap: "20px" }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
              <Select
                id="language"
                onChange={changeLanguage}
                value={i18n.language}
              >
                <MenuItem value={"en"}>EN</MenuItem>
                <MenuItem value={"ru"}>RU</MenuItem>
              </Select>
            </FormControl>
            <Link to="create-order">
              <Button
                variant="contained"
                sx={{ borderRadius: "50px", height: "100%", background: "linear-gradient(45deg, rgb(243, 112, 30), #fe2702)" }}
              >
                {t("Order")}
              </Button>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
