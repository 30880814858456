import React from "react";
import useRequest from "./RequestHook";

const useCreate = () => {
  const { data, isLoading, update, isSended, reset } = useRequest();

  const newCreate = React.useCallback(
    (resource, params) => {
      update((axiosInstance) => {
        let url = `/api/v1/${resource}`;

        return axiosInstance.post(url, params.data).then((response) => {
          return response.data
        });
      });
    },
    [update]
  );

  return { data, isLoading, isSended, reset, create: newCreate };
};

export default useCreate;